import { useFeatureIsOn } from "@growthbook/growthbook-react";
import useSWR from "swr";

export function useMyParticipations(axiosClient, config = {}) {
  const flag = useFeatureIsOn("promotions");
  return useSWR(
    () => (flag ? ["user/promotional-campaigns/my-participations"] : null),
    ([url]) => axiosClient.wrap(axiosClient.get(url).then((res) => res)),
    config
  );
}
